<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen" class="sidebar-panel">
              <div class="sidebar-header">
                <div class="app-user-avatar">
                  <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="User Avatar">
                </div>
                <div class="app-user-name">
                  <p v-if="user">  {{user.name}} </p>
                </div>
              </div>
              <ul class="sidebar-list">
                <li @click="closeSidebarPanel">
                  <router-link to="/account">
                    <i class="far fa-user"></i>
                    Contul meu
                  </router-link>
                </li>
                <li @click="closeSidebarPanel">
                  <router-link to="/orders">
                    <i class="fas fa-truck-loading"></i>
                    Comenzi
                  </router-link>
                </li>
              </ul>
            </div>
        </transition>
    </div>
</template>
<script>
import { store, mutations } from '@/store/index.js'

export default {
  name: 'Sidebar',
  data () {
    return {
      user: this.$store.getters.user
    }
  },
  methods: {
    closeSidebarPanel: mutations.toggleNav
  },
  computed: {
    isPanelOpen () {
      return store.isNavOpen
    }
  }
}
</script>
