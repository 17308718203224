import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Landing',
    component: () => import('../views/Landing/Landing.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/recover-password',
    name: 'ChangePassword',
    component: () => import('../views/Recover/ChangePassword.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account/Account.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'OrdersList',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History/History.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  store.dispatch('showLoading')
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['isLoggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    if (store.getters['isLoggedIn']) {
      next('/orders')
    }
    next()
  }
})

export default router
