import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ro from '../lang/ro.json'
import en from '../lang/en.json'
import axios from 'axios'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'ro', // set locale
  fallbackLocale: 'ro',
  messages: { ro } // set locale messages
})

const loadedLanguages = ['ro'] // our default language that is preloaded

function setI18nLanguage (lang) {
  window.localStorage.setItem('language', lang)
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  axios.defaults.headers.common['X-localization'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `../lang/${lang}.json`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default || msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
