<template>
<div class="app-header">
  <div id="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
  <div class="app-logout">
    <a href="javascript:void(0)" @click="logout()">
      <i class="fas fa-sign-out-alt"></i>
      Logout
    </a>
  </div>
</div>
</template>
<script>
import { store, mutations } from '@/store/index.js'

export default {
  name: 'Header',
  // data: () => ({
  //     isBurgerActive: false
  // }),
  computed: {
    isBurgerActive () {
      return store.isNavOpen
    }
  },
  methods: {
    toggle () {
      mutations.toggleNav()
    },
    logout () {
      this.$store.dispatch('showLoading')
      this.$store.dispatch('logoutRequest').then((response) => {
        this.$router.push({ name: 'Login' })
      })
    }
  }
}
</script>
