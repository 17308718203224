import axios from 'axios'

export default {
  login (data) {
    return axios.post('auth/login', data)
  },
  logout () {
    return axios.post('/auth/logout')
  },
  getAuthUser () {
    return axios.get('/user')
  },
  updateLocation (data) {
    return axios.post('/delivery-guy/location', data)
  }
}
