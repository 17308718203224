import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import router from './router'
import store from './store'
import localforage from 'localforage'
import vSelect from 'vue-select'
import './registerServiceWorker'
import { i18n } from './plugins/i18n.js'
import LoginService from '@/api-services/login.service.js'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = "https://classiasi.ro/delivery/api"
// axios.defaults.headers.common['X-localization'] = 'ro'
axios.defaults.responseType = 'json'
const token = localStorage.getItem('token') || null
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

localforage.config({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: 'DeliveryApp',
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: 'delivery-app', // Should be alphanumeric, with underscores.
  description: 'some description'
})

window._ = require('lodash')
window.$ = window.jQuery = require('jquery')

// Send location
function updateLocation () {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      // alert(position.coords.latitude + ' / ' + position.coords.longitude)
      let data = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      LoginService.updateLocation(data)
    })
  }
}
updateLocation()
setInterval(function () {
  updateLocation()
}, 10000)
// end

Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
