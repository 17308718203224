<template>
    <div id="app-wrapper">
        <Spinner v-if="this.$store.getters.loading"/>
        <Header/>
        <Sidebar/>
        <main>
          <div class="page-content">
            <router-view/>
          </div>
        </main>
    </div>
</template>

<script>

import Header from '../components/Partials/Header'
import Sidebar from '../components/Partials/Sidebar'
import Spinner from '../components/Partials/Spinner'

export default {
  name: 'Template',
  components: {
    Header,
    Sidebar,
    Spinner
  },
  mounted () {

  }
}
</script>
