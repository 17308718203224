<template>
    <div class="gooey">
        <div class="gooey-wrapper">
            <span class="dot"></span>
            <div class="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="css" scoped>
</style>
